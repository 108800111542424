import React from "react"
import { Jumbotron } from "reactstrap"
import { 
  Container,
  Row,
  Col,
} from "reactstrap"
import MainNavBar from "../components/mainnavbar" 
import Footer from "../components/mainfooter"
import "bootstrap/dist/css/bootstrap.min.css";

// O CSS da imagem está com 100% de largura e altura, dar atenção a isso.
export default () => (
  <div>
  <MainNavBar />
  <Jumbotron fluid className="hero hero_dort"></Jumbotron>
    <Container>
        <Row className="mb-3">
            <Col id="confissao" className="dort">
            <h1 className="display-3 text-center text_font_default font_variant_smallcaps">Os Cânones de Dort</h1>
            <hr class="fleuronColor"/>
            <p>O terceiro padrão doutrinário adotado pelas igrejas reformadas chama-se Cânones de Dort. Jacobus Arminius, pastor da Igreja Reformada em Amsterdã (dai os &ldquo;arminianos&rdquo;, adeptos ou defensores dos seus ensinamentos), afirmava que a salvação depende da presciência de Deus, no que foi contestado. Depois de sua morte, em 1609, o grupo arminiano publicou em 1610, &ldquo;A Remonstrância&rdquo; (pleito, pedido). O documento expunha o Arminianismo em cinco pontos: 1) Deus elegeu os que creriam; 2) o sacrifício de Cristo é para todos, mas somente o crente o recebe; 3) a fé é um dom da graça de Deus; 4) essa graça, porém, pode ser rejeitada; 5) os crentes também podem causar a própria perdição. </p>
            <p>Os teólogos fiéis à Escritura também publicaram um documento, a &ldquo;Contrarremonstrância&rdquo; e esclareceram o ponto de vista bíblico em sete artigos: 1) Deus em seu beneplácito, escolhe alguns a fim de salvá-los por Cristo, sendo as demais preteridas; 2) este decreto se aplica também as crianças; 3) aos eleitos; Deus dá a fé; 4) para eles vale o sacrifício reconciliatório de Cristo; 5) o Espirito os renova; 6) o Espirito os guarda na fé; 7) eles mostram a gratidão por meio das obras.</p>
            <p>No dia 13 de Novembro de 1618, o Sinodo reuniu-se em Dort, ou Dortrech, na Holanda, para decidir a questão. Os cinco artigos dos arminianos foram discutidos e uma comissão preparou o texto dos &ldquo;cânones&rdquo; ou regras de doutrina em que se condenava a doutrina arminiana e se expunha a doutrina da Escritura.</p>
            <p>Os Cânones de Dort foram aceitos por todos os delegados e solenemente promulgados. As igrejas reformadas dos Países Baixos tinham agora sua terceira confissão de fé, ao lado da Confissão Belga (1561) e do Catecismo de Heidelberg (1563).</p>
<h2>1º Capítulo da Doutrina</h2>
<h3>A Divina Eleição e Reprovação</h3>

<h4>Toda a humanidade é condenável perante Deus</h4>
<p>Todos os homens pecaram em Adão, estão debaixo da maldição de Deus e são condenados à morte eterna. Por isso ninguém teria sido injustiçado se ele tivesse resolvido deixar toda a raça humana no pecado e sob a maldição e decidido condená la por causa do seu pecado, de acordo com as palavras do apóstolo: <q>que se cole toda boca, e todo o mundo seja culpável perante Deus&hellip; pois todos pecaram e carecem da glória de Deus&hellip; e&hellip; o salário do pecado é a morte</q> <cite>(Rm 3.19,23; Rm 6.23)</cite></p>

<h4>O envio do Filho de Deus</h4>
<p><q>Mas nisto se manifestou o amor de Deus em nós, em haver Deus enviado o seu Filho unigênito ao mundo&hellip; para que todo o que nele crê não pereça, mas tenha a vida eterna.</q> <cite>(1Jo 4.9; Jo 3.16)</cite></p>

<h4>A pregação do Evangelho</h4>
<p>Para que os homens sejam conduzidos à fé, Deus envia, em sua misericórdia, mensageiros dessa alegre boa nova a quem e quando ele quer. Pelo ministério deles, os homens são chamados ao arrependimento e à fé no Cristo crucificado. <q>Como crerão naquele de quem nada ouviram? E como ouvirão, se não há quem pregue? E como pregarão se não forem enviados?</q> <cite>(Rm 10.14–15)</cite></p>

<h4>Um duplo resultado</h4>
<p>A ira de Deus permanece sobre aqueles que não creem no Evangelho. Mas aqueles que o aceitam e abraçam a Jesus, o Salvador, com uma fé verdadeira e viva, são redimidos por ele da ira de Deus e da perdição, e presenteados com a vida eterna <cite>(Jo 3.36; Mc 16.16)</cite>.</p>

<h4>A causa da incredulidade e a fonte da fé</h4>
<p>Em Deus não está, de forma alguma, a causa ou culpa dessa incredulidade. O homem tem essa culpa assim como a de todos os demais pecados. Mas a fé em Jesus Cristo e também a salvação por meio dele são dons gratuitos de Deus, como está escrito: <q>Pela graça sois salvos, mediante a fé; e isto não vem de vós, é dom de Deus</q> <cite>(Ef 2.8)</cite>.</p>
<p>Semelhantemente, <q>Vos foi concedida a graça de&hellip; crer em Cristo</q> <cite>(Fp 1.29)</cite>.</p>

<h4>Decreto eterno de Deus</h4>
<p>Deus nesta vida concede a fé a alguns enquanto não concede a outros. Isto procede do eterno decreto de Deus. Porque as Escrituras dizem que ele&hellip; <q>faz estas coisas conhecidas desde séculos</q> e que&hellip; ele faz todas as coisas conforme o conselho da sua vontade&hellip; <cite>(At 15.18; Ef 1.11)</cite>. De acordo com este decreto, ele graciosamente quebranta os corações dos eleitos, por duros que sejam, e os inclina a crer. Pelo mesmo decreto, entretanto, segundo seu justo juizo, ele deixa os não eleitos em sua própria maldade e dureza de coração. E aqui especialmente nos é manifesta a profunda, misericordiosa e ao mesmo tempo justa distinção entre homens que estão sob a mesma condição de perdição. Este é o decreto da eleição e reprovação revelado na Palavra de Deus.</p>
<p>Ainda que os homens perversos, impuros e instáveis o deturpem, para sua própria perdição, ele dá um inexprimível conforto para as pessoas santas e tementes a Deus.</p>

<h4>Eleição definida</h4>
<p>Esta eleição é o imutável propósito de Deus, pelo qual ele, antes da fundação do mundo, escolheu um número grande e defini  do de pessoas para a salvação, por graça pura. Estas são escolhidas de acordo com o soberano bom propósito de sua vontade, dentre todo o gênero humano, decaído, por sua própria culpa, de sua integridade original para o pecado e a perdição. Os eleitos não são melhores ou mais dignos que os outros, mas envolvidos na mesma miséria. São escolhidos, porém, em Cristo, a quem Deus constituiu, desde a eternidade, Mediador e Cabeça de todos os eleitos e fundamento da salvação. E, para salvá los por Cristo, Deus decidiu dá los a ele e efetivamente chamá los e atrai los à sua comunhão por meio da sua Palavra e de seu Espírito. Em outras palavras, ele decidiu dar lhes verdadeira fé em Cristo, justificá los, santificá los, e depois, tendo se guardado poderosamente na comunhão de seu Filho, finalmente glorificá los. Deus fez isto para a demonstração de sua misericórdia e para o louvor da riqueza de sua gloriosa graça. Como está escrito <q>&hellip;<span>[O Pai]</span> nos escolheu nele, antes do fundação do mundo, para sermos santos e irrepreensíveis perante ele; e em amor nos predestinou para ele, para a adoção de filhos, por meio de Jesus Cristo, segundo o beneplácito de sua vontade, para louvor da glória de sua graça, que ele nos concedeu gratuitamente no Amado</q> E em outro lugar: <q>Aos que predestinou, a esses também chamou; e aos que chamou, a esses também justificou; e aos que justificou, a esses também, glorificou</q> <cite>(Ef 1.4–6; Rm 8.30)</cite>.</p>

<h4>Um só decreto de eleição</h4>
<p>Esta eleição é múltipla, mas ela é uma e a mesma de todos os que são salvos tanto no Antigo Testamento quanto no Novo Testamento. Pois a Escritura nos prega o único bom propósito e conselho da vontade de Deus, pelo qual ele nos escolheu desde a eternidade, tanto para a graça como para a glória, assim também para a salvação e para o caminho da salvação, o qual preparou para que andássemos nele <cite>(Ef 1.4–5; Ef 2.10)</cite>.</p>

<h4>Eleição não baseado em fé previste</h4>
<p>Esta eleição não é baseada em fé prevista, em obediência de fé, santidade ou qualquer boa qualidade ou disposição, que seria uma causa ou condição previamente requerida ao homem para ser escolhido. Ao contrário, esta eleição é para a fé, a santidade, etc. Eleição, portanto, que é a fonte de todos os bens da salvação e, finalmente tem, a própria vida eterna como seu fruto. É conforme o testemunho do apóstolo: Ele&hellip; <q>nos escolheu</q>&hellip; não por sermos mas&hellip; <q>para sermos santos e irrepreensíveis perante ele</q>&hellip; <cite>(Ef 1.4)</cite>.</p>

<h4>Eleição baseada no bom propósito de Deus</h4>
<p>A causa desta eleição graciosa é somente o bom propósito de Deus. Este bom propósito não consiste no fato de que dentre todas as condições possíveis Deus tenha escolhido certas qualidades ou ações dos homens como condição para a salvação. Mas este bom propósito consiste no fato de que Deus adotou certas pessoas dentre a multidão inteira de pecadores para ser sua propriedade.</p>
<p>Como está escrito: <q>E ainda não eram os gêmeos nascidos nem tinham praticado o bem ou o mal&hellip; já lhe fora dito a ela <span>[Rebeca]</span>: O mais velho será servo do mais moço. Como está escrito: Amei a Jacó, porém me aborreci de Esaú. E&hellip; creram os que haviam sido destinados para a vida eterna</q> <cite>(Rm 9.11–13; At 13.48)</cite>.</p>

<h4>Eleição imutável</h4>
<p>Como Deus é supremamente sábio, imutável, onisciente e Todo Poderoso, assim sua eleição não pode ser cancelada e depois renovada, nem alterada, revogada ou anulada; nem mesmo podem os eleitos ser rejeitados ou o número deles ser diminuído.</p>

<h4>A certeza da eleição</h4>
<p>Os eleitos recebem, no devido tempo, a certeza da sua eterna e imutável eleição para a salvação, ainda que em vários graus e em medidas desiguais. Eles não a recebem quando curiosamente investigam os mistérios e profundezas de Deus. Mas eles a recebem quando observam em si mesmos, com alegria espiritual e gozo santo, os infalíveis frutos de eleição indicados na Palavra de Deus, tais como uma fé verdadeira em Cristo, um temor filial para com Deus, tristeza por seus pecados contra a vontade de Deus e fome e sede de justiça.</p>

<h4>O valor dessa certeza</h4>
<p>A consciência e a certeza desta eleição daria aos filhos de Deus maior motivo para se humilharem perante ele, para adorarem a profundidade de sua misericórdia, para se purificarem e para amarem ardentemente aquele que primeiro tanto os amou. Contudo não é absolutamente verdade que esta doutrina da eleição e a reflexão sobre a mesma os façam relaxar na observação dos mandamentos de Deus ou rendam segurança falsa. No justo julgamento de Deus isto ocorre frequentemente àqueles que se vangloriam levianamente da graça da eleição ou facilmente falam acerca disso, mas se recusam andar nos caminhos dos eleitos.</p>

<h4>Como a eleição deve ser ensinada</h4>
<p>A doutrina da divina eleição, segundo o mui sábio conselho de Deus, foi pregada pelos profetas, por Cristo mesmo e pelos apóstolos, tanto no Antigo Testamento como no Novo Testamento, e depois escrita e entregue a nós através das Escrituras Sagradas. Por isso, também hoje esta doutrina deve ser ensinada no seu devido tempo e lugar na Igreja de Deus, para a qual ela foi particularmente destinada. Ela deve ser ensinada com espírito de discrição, de modo reverente e santo, sem curiosa investigação dos caminhos do Altíssimo, para a glória do santo nome de Deus e consolação vivificante do seu povo.</p>

<h4>Reprovação descrita</h4>
<p>A Escritura Sagrada mostra e recomenda a nós esta graça eterna e imerecida sobre nossa eleição, especialmente quando, além disso, testifica que nem todos os homens são eleitos; alguns, pois, são preteridos na eleição eterna de Deus. De acordo com seu soberano, justo, irrepreensível e imutável bom propósito, Deus decidiu deixá-los na miséria comum em que se lançaram por sua própria culpa, não lhes concedendo a fé salvadora e a graça da conversão. Para mostrar sua justiça, decidiu deixá-los em seus próprios caminhos e debaixo do seu justo julgamento e, finalmente, condená-los e puni-los eternamente, não apenas por causa de sua incredulidade, mas também por todos os seus pecados, para mostrar sua justiça. Este é o decreto da reprovação, o qual não torna Deus o autor do pecado (tal pensamento é blasfêmia!), mas o declara o temível, irrepreensível e justo Juiz e Vingador do pecado.</p>

<h4>Como a doutrina do reprovação deve ser recebida</h4>
<p>Há pessoas que não sentem fortemente a fé viva em Cristo, nem firme confiança no coração, nem paz na boa consciência, nem zelo pela obediência filial e pela glorificação de Deus por meio de Cristo.</p>
<p>Apesar disso elas usam os meios pelos quais Deus prometeu operar tais coisas em nós. Elas não devem desanimar quando a reprovação for mencionada nem contar a si mesmas entre os reprovados. Pelo contrário, devem continuar diligentemente no uso desses meios, desejando fervorosamente dias de graça mais abundante e esperando os com reverência e humildade. Não devem se assustar de maneira nenhuma com a doutrina da reprovação os que desejam seriamente se converter a Deus, agradar só a ele e serem libertos do corpo de morte, mas ainda não podem chegar no ponto que gostariam no caminho da piedade e da fé. O Deus misericordioso prometeu não apagar a torcida que fumega, nem esmagar a cana quebrada. Mas esta doutrina é certamente assustadora para os que não contam com Deus e o Salvador Jesus Cristo e se entregaram completamente às preocupações do mundo e aos desejos da carne, enquanto não se convertem seriamente a Deus.</p>

<h4>Filhos de crentes que morrem na infância</h4>
<p>Devemos julgar a respeito da vontade de Deus com base na sua Palavra. Ela testifica que os filhos de crentes são santos, não por natureza mas em virtude da aliança da graça, na qual estão incluídos com seus pais. Por isso os pais que temem a Deus não devem ter dúvida da eleição e salvação de seus filhos, que Deus chama desta vida ainda na infância.</p>

<h4>Protesto não, e sim, adoração</h4>
<p>Àqueles que reclamam contra esta graça de eleição imerecida e a severidade da justa reprovação nós replicamos com esta sentença do apóstolo: <q>Quem és tu, ó homem para discutires com Deus?</q> <cite>(Rm 9.20)</cite>. E com esta palavra do Salvador: <q>Porventura não me é lícito fazer o que quero do que é meu?</q> <cite>(Mt 20.15)</cite>. Nós, entretanto, adorando reverentemente estes mistérios, exclamamos com o apóstolo: <q>Ó profundidade do riqueza, tanto do sabedoria como do conhecimento de Deus! Quão insondáveis são os seus juizos e quão inescrutáveis os seus caminhos! Quem, pois, conheceu a mente do Senhor? Ou quem foi o seu conselheiro? Ou quem primeiro lhe deu a ele para que lhe venha a ser restituído? Porque dele e por meio dele e para ele são todas as coisas. A ele, pois, a glória eternamente. Amém</q> <cite>(Rm 11.33–36)</cite>.</p>

<hr />
<div class="erros">
    <h3>Rejeição de erros</h3>
    <p>Tendo explicado a doutrina ortodoxa de eleição e reprovação, o Sínodo rejeita os seguintes erros:</p>
    <p>A vontade de Deus para salvar aqueles que crerem e perseverarem na fé e na obediência da fé é o decreto inteiro e total da eleição para a salvação. Nada mais sobre este decreto foi revelado na Palavra de Deus.</p>
    <p>Este erro engana aos simples e claramente contradiz a Escritura. Ela testifica não apenas que Deus salvará aqueles que creem, mas também que escolheu pessoas específicas desde a eternidade. Nesta vida, ele dará para esses eleitos a fé em Cristo e a perseverança, que ele não dá a outros; como está escrito: <q>Manifestei o teu nome aos homens que me deste do mundo</q> <cite>(Jo 17.6)</cite>; <q>e creram todos os que haviam sido destinados para a vida eterna</q> <cite>(At 13.48)</cite>, [o Pai] <q>nos escolheu nele antes da fundação do mundo, para sermos santos e irrepreensíveis perante ele</q> <cite>(Ef 1.4)</cite>.</p>
    <p>Há vários tipos de eleição divina para a vida eterna. Uma é geral e indefinida, e outra é particular e definida. Esta última eleição ou é incompleta, revogável, não decisiva e condicional, ou é completa, irrevogável, decisiva e absoluta. Do mesmo modo, há uma eleição para a fé e outra para a salvação. Portanto a eleição pode ser para a fé justificante, sem ser decisiva para a salvação.</p>
    <p>Isto é uma invenção da mente humana, sem nenhuma base na Escritura. Essa invenção corrompe a doutrina da eleição e quebra a corrente de ouro da nossa salvação. <q>E aos que predestinou, a esses também chamou; e aos que chamou, a esses também justificou; e aos que justificou, a esses também glorificou</q> <cite>(Rm 8.30)</cite>.</p>
    <p>O bom propósito de Deus do qual a Escritura fala na doutrina da eleição não significa que ele escolheu certas pessoas e não outras, mas que ele, dentre todas as condições possíveis (inclusive as obras da lei), ou seja, dentre todas as possibilidades, escolheu como condição de salvação o ato de fé, que é, de si mesmo, sem mérito, e a obediência imperfeita da fé. Na sua graça ele a considera como obediência perfeita e digna da recompensa da vida eterna.</p>
    <p>Este erro perigoso invalida o bom propósito de Deus e o mérito de Cristo, e desvia as pessoas, por questões inúteis, da verdade da justificação graciosa e da simplicidade da Escritura. Ele acusa de falsidade esta declaração do apóstolo: <q>que nos salvou e nos chamou com santa vocação; não segundo as nossas obras, mas conforme a sua própria determinação e graça que nos foi dada em Cristo Jesus antes dos tempos eternos</q> <cite>(2 Tm 1.9)</cite>.</p>
    <p>A eleição para a fé depende das seguintes condições prévias: o homem deve fazer uso adequado da luz da natureza e deve ser piedoso, humilde, submisso e qualificado para a vida eterna.</p>
    <p>Assim parece que a eleição depende destas coisas. Isto tem o sabor do ensinamento de Pelágio e está em conflito com o ensino do apóstolo em Efésios 2.3–9: <q>entre os quais também todos nós andamos outrora, segundo as inclinações da nossa carne, fazendo a vontade da carne e dos pensamentos; e éramos por natureza filhos da ira, como também os demais. Mas Deus, sendo rico em misericórdia, por causa do grande amor com que nos amou, e estando nós mortos em nossos delitos, nos deu vida juntamente com Cristo  pela graça sois salvos  e juntamente com ele nos ressuscitou e nos fez assentar nos lugares celestiais em Cristo Jesus; para mostrar nos séculos vindouros a suprema riqueza da sua graça e bondade para conosco em Cristo Jesus, Porque pela graça sois salvos, mediante a fé; e isto não vem de vós; é dom de Deus; não de obras, para que ninguém se glorie</q>.</p>
    <p>A eleição incompleta e não definida de certas pessoas para a salvação se baseou nisto:
    <br />Deus previu que elas começariam a crer, se converteriam, viveriam em santidade e piedade, e que  até continuariam nisto por algum tempo. Eleição completa e definitiva de pessoas, porém, ocorreu porque Deus previa que elas perseverariam em fé, conversão, santidade e piedade até o fim. Isto é, constitui a dignidade graciosa e evangélica pela qual a pessoa que é escolhida é mais digna que outra que não é escolhida. Consequentemente a fé, a obediência de fé, a piedade e a perseverança não são frutos da imutável eleição para glória. São condições e causas previamente requeridas e previstas como cumpridas naqueles que serão eleitos completamente. Só com base nestas condições ocorre a eleição imutável para a glória.</p>
    <p>Este erro está em conflito com toda a Escritura que repete constantemente, para nossos ouvidos e corações, estas e semelhantes afirmações: a eleição não <q><span>[se dá]</span> por obras mas por aquele que chama</q> <cite>(Rm 9. 11)</cite>; <q>e creram todos os que haviam sido destinados para a vida eterna</q> <cite>(At 13.48)</cite> <q>nos escolheu nele antes da fundação do mundo para sermos santos e irrepreensíveis perante ele</q> <cite>(Ef 1.4)</cite>; <q>não fstes vós que me escolhestes a mim; pelo contrário, eu vos escolhi a vós outros</q> <cite>(Jo 15. 16)</cite>; <q>se é pela graça, já não é pelas obras; do contrário, a graça já não é graça</q> <cite>(Rm 11.6)</cite>. <q>Nisto consiste o amor, não em que nós tenhamos amado a Deus, mas em que ele nos amou, e enviou o seu filho</q> <cite>(1Jo 4. 10)</cite>.</p>
    <p>Nem toda eleição para a salvação é imutável. Alguns dos eleitos podem perder se e de fato se perdem eternamente, não obstante qualquer decreto de Deus.</p>
    <p>Este erro grosseiro faz Deus mutável, destrói o conforto dos crentes quanto à constância de sua eleição e contradiz a Escritura: os eleitos não podem ser enganados <cite>(Mt 24.24)</cite>. <q>E a vontade de quem me enviou é esta: que nenhum eu perca de todos os que me deu</q> <cite>(Jo 6.39)</cite>; <q>E aos que predestinou a esses também chamou; e aos que chamou a esses justificou; e aos que justificou a esses também glorificou</q> <cite>(Rm 8.30)</cite>.</p>
    <p>Nesta vida não há fruto, consciência ou certeza da eleição imutável para a glória, exceto a certeza que depende de uma condição mutável e incerta.</p>
    <p>Falar acerca de uma certeza incerta é não apenas absurdo mas também contrário à experiência dos santos. Sentindo sua eleição, eles se regozijam junto com o apóstolo e glorificam este beneficio de Deus <cite>(cf. Ef 1.12)</cite>. Conforme o mandamento de Cristo eles se regozijam junto com os discípulos por seus nomes estarem escritos nos céus <cite>(Lc 10.20)</cite>. Eles colocam a consciência de sua eleição contra os dardos inflamados das tentações do diabo, quando perguntam: <q>Quem intentará acusação contra os eleitos de Deus?</q> <cite>(Rm 8.33)</cite>.</p>
    <p>Deus não decidiu, simplesmente com base em sua justa vontade, deixar ninguém na queda de Adão e no estado comum de pecado e condenação. Nem decidiu preterir ninguém quando deu a graça, necessária para a fé e a conversão.</p>
    <p>Pois isto é certo: <q>Tem ele misericórdia de quem quer, e também endurece a quem lhe apraz</q> <cite>(Rm 9.18)</cite>. E também isto: <q>a vós outros é dado conhecer os mistérios do reino dos céus, mas àqueles não lhes é isso concedido</q> <cite>(Mt 13.11)</cite>. Igualmente: <q>Graças te dou, ó Pai, Senhor do céu e do terra, porque ocultaste estas coisas aos sábios e entendidos, e as revelaste aos pequeninos. Sim, ó Pai, porque assim foi de teu agrado</q> <cite>(Mt 11.25–26)</cite>.</p>
    <p>Deus envia o Evangelho a um povo mais que a um outro, não meramente e somente por causa do bom propósito de sua vontade, mas por ser este melhor e mais digno que o outro, ao qual o Evangelho não é comunicado.</p>
    <p>Moisés nega isto quando se dirige ao povo de Israel dizendo: <q>Eis que os céus e os céus dos céus são do <span class="smallcaps">Senhor</span> teu Deus, a terra e tudo o que nela há. Tão somente o <span class="smallcaps">Senhor</span> se afeiçoou a teus pais para os amar: a vós outros, descendentes deles, escolheu de todos os povos, como hoje se vê</q> <cite>(Dt 10.14–15)</cite>. E Cristo diz: <q>Ai de ti, Corazim! Ai de ti, Betsaida! Porque, se em Tiro e em Sidorn se tivessem operado os milagres que em vós se fizeram, há muito que elas se teriam arrependido, com pano de saco e cinza</q> <cite>(Mt 11.21)</cite>.</p>
</div>
<hr />
<h2>2º Capítulo da Doutrina</h2>
<h3>A morte de Cristo e a redenção do homem por meio dela</h3>

<h4>A Justiça de Deus exige punição</h4>
<p>Deus é não só supremamente misericordioso mas também supremamente justo. E como ele se revelou em sua Palavra, sua justiça exige que nossos pecados, cometidos contra sua infinita majestade, sejam punidos nesta vida e na futura, em corpo e alma. Não podemos escapar dessas punições a menos que seja satisfeita a justiça de Deus.</p>

<h4>A satisfação cumprida por Cristo</h4>
<p>Por nós mesmos, entretanto, não podemos <em>cumprir tal satisfação</em> nem podemos livrar a nós mesmos da ira de Deus. Por isso Deus, em sua infinita misericórdia, deu seu Filho único como nosso fiador. Por nós, ou em nosso lugar, ele foi feito pecado e maldição na cruz para que pudesse satisfazer a Deus por nós.</p>

<h4>O valor infinito da morte de Cristo</h4>
<p>Esta morte do Filho de Deus é o único e perfeito sacrifício pelos pecados, de valor e dignidade infinitos, abundantemente suficiente para expiar os pecados do mundo inteiro.</p>

<h4>Sua morte tem valor infinito</h4>
<p>Essa morte é de tão grande poder e valor porque quem se submeteu a ela era não apenas homem verdadeiro e perfeitamente santo, mas também o filho único de Deus. Ele é Deus eterno e infinito junto ao Pai e ao Espírito Santo. Assim devia ser nosso Salvador. Além disso ele sentiu, ao morrer, a ira e a maldição de Deus que nós merecemos pelos nossos pecados.</p>

<h4>A proclamação universal do Evangelho</h4>
<p>A promessa do Evangelho é que todo aquele que crêr no Cristo crucificado não pereça, mas tenha a vida eterna. Esta promessa deve ser anunciada e proclamada sem discriminação a todos os povos e a todos os homens, aos quais Deus, em seu bom propósito, envia o Evangelho com a ordem de que se arrependam e creiam.</p>

<h4>Por que alguns não creem</h4>
<p>Muitos que têm sido chamados pelo Evangelho não se arrependem nem creem em Cristo, mas perecem na incredulidade. Isto não acontece por causa de algum defeito ou insuficiência no sacrificio de Cristo na cruz, mas por culpa deles próprios.</p>

<h4>Por que outros creem</h4>
<p>Mas aqueles que verdadeiramente creem e pela morte de Cristo são libertos e salvos dos seus pecados e da perdição, recebem tal beneficio apenas por causa da graça de Deus, que lhes é dada, em Cristo, desde a eternidade. Deus não deve a ninguém tal graça.</p>

<h4>A eficácia da morte de Cristo</h4>
<p>Pois este foi o soberano conselho, a vontade graciosa e o propósito de Deus, o Pai, que a eficácia vivificante e salvifica da preciosissima morte de seu Filho fosse estendida a todos os eleitos. Daria somente a eles a justificação pela fé e por conseguinte os traria infalivelmente à salvação. Isto quer dizer que foi da vontade de Deus que Cristo, por meio do sangue na cruz (pelo qual ele confirmou a nova aliança), redimisse efetivamente, de todos os povos, tribos, línguas e nações, todos aqueles e somente aqueles que foram escolhidos desde a eternidade para serem salvos e lhe foram dados pelo Pai. Deus quis que Cristo lhes desse a fé, que ele mesmo lhes conquistou com sua morte, juntamente com outros dons salvificos do Espírito Santo. Deus quis também que Cristo os purificasse de todos os pecados por meio do seu sangue, tanto do pecado original como dos pecados atuais, que foram cometidos antes e depois de receberem a fé. E que Cristo os guardasse fielmente até o fim e finalmente os fizesse comparecer perante o próprio Pai em glória, <q>sem mácula, nem ruga</q> <cite>(Ef 5.27)</cite>.</p>

<h4>O cumprimento do conselho de Deus</h4>
<p>Este conselho, procedendo do amor eterno de Deus aos eleitos, tem sido poderosamente cumprido, desde o começo do mundo até hoje, ainda que as &ldquo;portas do inferno&rdquo; em vão tentem frustrá-lo. O conselho de Deus também continuará a ser cumprido. No devido tempo os eleitos serão unidos em um só rebanho, e sempre haverá uma Igreja de crentes fundada no sangue de Cristo. Esta Igreja ama firmemente seu Salvador (o qual como noivo deu na cruz sua própria vida por sua noiva), serve a ele com perseverança e o glorifica agora e para sempre.</p>

<hr />
<div class="erros">
    <h3>Rejeição de erros</h3>
    <p>Tendo explicado a doutrina ortodoxa, o Sínodo rejeita os seguintes erros:</p>
    <p>Deus, o Pai, destinou seu Filho à morte na cruz sem um decreto definido de determinadas pessoas. Mesmo que a redenção por Cristo, conquistada de fato, nunca tivesse sido aplicada a nem uma só pessoa, o que ele alcançou pela sua morte podia ter sido necessário, proveitoso e valioso e podia permanecer perfeito, completo e intacto em todas as suas partes.</p>
    <p>Esta doutrina é uma ofensa à sabedoria do Pai, ao mérito de Cristo e é contrária à Escritura. Pois o nosso Salvador afirma: <q>dou a minha vida pelas ovelhas e eu as conheço</q> <cite>(Jo 10.15,27)</cite>. E o profeta fala acerca do Salvador: <q>quando der ele a sua alma como oferta pelo pecado, verá a sua posteridade e prolongará os seus dias; e a vontade do SENHOR prosperará nas suas mãos</q> <cite>(Is 53.10)</cite>. Finalmente, este erro invalida o artigo de fé pelo qual confessamos a Igreja universal de Cristo.</p>
    <p>Não era propósito da morte de Cristo que' ele confirmasse de fato a nova aliança da graça pelo seu sangue. Mas era somente propósito que conquistasse para o Pai o mero direito de estabelecer de novo uma aliança com o homem, seja de graça, seja de obras, conforme a vontade do Pai.</p>
    <p> Refutação   Isto contradiz a Escritura que ensina que Cristo se tornou o Fiador e Mediador de uma aliança superior, isto é, da nova aliança. Um testamento só se concretiza em caso de morte <cite>(Hb 7.22 e 9.15,17)</cite>.</p>
    <p>Por sua satisfação ao Pai, Cristo não mereceu para ninguém a salvação segura nem a fé pela qual esta satisfação para salvação é efetivamente aplicada. Ele apenas obteve para o Pai a possibilidade ou a vontade perfeita, para tratar de novo com o homem e para prescrever novas condições conforme sua vontade. Depende entretanto da livre vontade do homem preencher essas condições. Portanto poderia acontecer que ninguém ou todos os homens preenchessem tais condições.</p>
    <p>Aqueles que ensinam este erro desprezam a morte de Cristo e não reconhecem de maneira nenhuma o seu mais importante resultado ou benefício. Eles evocam do inferno o erro pelagiano.</p>
    <p>A nova aliança da graça, que Deus. o Pai, mediante a morte de Cristo, estabeleceu com o homem, não consiste em que nós estamos justificados diante de Deus e salvos pela fé se ela aceita o mérito de Cristo. Ela consiste no fato de que Deus revogou a exigência de perfeita obediência à lei e considera agora a própria fé e a obediência de fé, ainda que imperfeitas, como a perfeita obediência à lei. Ele acha, em sua graça, que elas sejam dignas da recompensa da vida eterna.</p>
    <p>Os que ensinam isto contradizem a Escritura: <q>sendo justificados gratuitamente, por sua graça, mediante a redenção que há em Cristo Jesus, a quem Deus propôs, no seu sangue, como propiciação, mediante a fé</q> <cite>(Rm 3.24–25)</cite>. Eles introduzem, junto com o impio Socino, uma nova e estranha justificação do homem diante de Deus, contrária ao consenso da Igreja inteira.</p>
    <p>Todas as pessoas têm sido aceitas por Deus, de tal maneira que estão reconciliadas com ele e participam da aliança. Por isso ninguém está sujeito à condenação ou será condenado por causa do pecado original. Todos estão livres da culpa deste pecado.</p>
    <p>Esta opinião contraria a Escritura que ensina que nós somos <q>por natureza filhos da ira</q> <cite>(Ef 2.3)</cite>.</p>
    <p>Deus, por sua parte, quer dar a todas as pessoas igualmente os beneficios conquistados pela morte de Cristo. Entretanto algumas obtêm o perdão dos pecados e a vida eterna, e outras não. Esta distinção depende de sua própria livre vontade que se junta à graça que é oferecida sem distinção. Mas não depende do dom especial da misericórdia que nelas opera tão poderosamente que elas se apropriem dessa graça, diferentemente das outras pessoas.</p>
    <p>Os que assim ensinam abusam da distinção entre aquisição e apropriação da salvação para implantar esta opinião nas mentes de pessoas imprudentes e sem experiência. Enquanto eles simulam apresentar esta distinção da maneira correta, procuram induzir na mente do povo o perigoso veneno dos erros pelagianos.</p>
    <p>Cristo não podia nem precisava morrer de fato, por aqueles a quem Deus amou supremamente e elegeu para a vida eterna, visto que estes não precisavam da morte de Cristo.</p>
    <p>Esta doutrina contradiz o apóstolo, que declara: O Filho de Deus <q>me amou e a si mesmo se entregou por mim</q> <cite>(Gl 2.20)</cite>. Igualmente: <q>Quem intentará acusação contra os eleitos de Deus? É Deus quem os justifica. Quem os condenará? É Cristo Jesus quem morreu</q>&hellip; por eles <cite>(Rm 8.33–34)</cite>. E o Salvador assegura: <q>Dou a minha vida pelas minhas ovelhas</q> <cite>(Jo 10.15)</cite>. E mais: <q>O meu mandamento é este, que vos ameis uns aos outros, assim como eu vos amei. Ninguém tem maior amor do que este: de dar alguém a própria vida em favor dos seus amigos.</q> <cite>(Jo 15.12–13)</cite>.</p>
</div>
<hr />
<h2>3º e 4º Capítulos da Doutrina</h2>
<h3>A corrupção do homem, a sua conversão a Deus e como ela ocorre</h3>

<h4>O resultado do queda</h4>
<p>No princípio o homem foi criado à imagem de Deus. Foi adornado em seu entendimento com o verdadeiro e salutar conhecimento de Deus e de todas as coisas espirituais. Sua vontade e seu coração eram retos, todos os seus afetos, puros; portanto, era o homem completamente santo. Mas desviando se de Deus sob instigação do diabo e pela sua livre vontade, ele se privou desses dons excelentes. Em lugar disso trouxe sobre si cegueira, trevas terríveis, leviano e perverso juizo em seu entendimento; malícia, rebeldia e dureza em sua vontade e em seu coração; e ainda impureza em todos os seu afetos.</p>

<h4>Corrupção espalhada</h4>
<p>Depois da queda, o homem corrompido gerou filhos corrompidos. Então a corrupção, de acordo com o justo julgamento de Deus, passou de Adão até todos os seus descendentes, com exce ção de Cristo somente. Não passou por imitação, como os antigos pelagianos afirmavam, mas por procriação da natureza corrompida.</p>

<h4>Incapacidade total do homem</h4>
<p>Portanto, todos os homens são concebidos em pecado e nascem como filhos da ira, incapazes de qualquer ação que o salve, inclinados para o mal, mortos no pecado e escravos do pecado. Sem a graça do Espírito Santo regenerador não desejam nem tampouco podem retornar a Deus, corrigir sua natureza corrompida ou ao menos estar dispostos a essa correção.</p>

<h4>A Insuficiência da luz da natureza</h4>
<p>É verdade que há no homem depois da queda um resto de luz natural. Assim ele retém ainda alguma noção sobre Deus, sobre as coisas naturais e a diferença entre honra e desonra e pratica alguma virtude e disciplina exterior. Mas o homem está tão longe de chegar ao conhecimento salvífico de Deus e à verdadeira conversão por meio desta luz natural que ele não a usa apropriadamente nem mesmo em assuntos cotidianos. Antes, qualquer que seja esta luz, o homem a polui totalmente, de maneiras diversas, e a detém pela injustiça. Assim, ele se faz indesculpável perante Deus.</p>

<h4>A insuficiência da lei</h4>
<p>O que foi dito à luz da natureza vale também com relação à lei dos Dez Mandamentos, dada por Deus através de Moisés, particularmente aos judeus. A lei revela como é grande o pecado e mais e mais convence o homem de sua culpa, mas não aponta o remédio nem dá a força para sair dessa miséria. A lei ficou sem força pela carne e deixa o transgressor sob a maldição. Por esta razão o homem não pode obter a graça salvadora através da lei.</p>

<h4>A necessidade do Evangelho</h4>
<p>Aquilo que nem a luz natural nem a lei podem fazer, Deus o faz pelo poder do Espírito Santo e pela pregação ou ministério da reconciliação, que é o Evangelho do Messias. Agradou a Deus usar este Evangelho para salvar os crentes, tanto na antiga quanto na nova aliança.</p>

<h4>Por que o Evangelho é enviado a alguns e a outros não</h4>
<p>No Antigo Testamento Deus revelou a poucas pessoas este mistério da sua vontade. No Novo Testamento, entretanto, ele retirou a distinção entre os povos e revelou o mistério a muito mais pessoas. Esta distribuição distinta do Evangelho não é motivada pela maior dignidade de um certo povo, nem pelo melhor uso da luz da natureza, mas pelo soberano bom propósito e amor imerecido de Deus. Portanto eles, que recebem tão grande graça, além e ao contrário de tudo que merecem, devem reconhecer isto com coração humilde e agradecido. Mas eles devem, com o após  tolo, adorar a severidade e justiça dos julgamentos de Deus sobre aqueles que não recebem esta graça, mas não devem, de maneira nenhuma, investigá los curiosamente.</p>

<h4>O sério chamado pelo Evangelho</h4>
<p>Tantos quantos; são chamados pelo Evangelho, o são seriamente. Porque Deus revela séria e sinceramente em sua Palavra o que lhe agrada, a saber, que aqueles que são chamados venham a ele. Ele também seriamente promete descanso para a alma e vida eterna a todos que a ele vierem e crerem.</p>

<h4>Por que alguns que são chamados não vêm</h4>
<p>Muitos são chamados através do ministério do Evangelho, mas não vêm nem são convertidos. A culpa não é do Evangelho, nem de Cristo, que é oferecido pelo Evangelho, nem de Deus, que os chama através do Evangelho e inclusive lhes confere vários dons. A culpa é deles mesmos. Alguns não aceitam a Palavra da vida por descuido. Outros de fato a recebem, mas não em seus corações e, por isso, quando desaparece a alegria de sua fé temporária, viram as costas à Palavra. Ainda outros sufocam a semente da Palavra com os espinhos dos cuidados e prazeres deste mundo e não produzem nenhum fruto. Isto é o que o Salvador ensina na parábola do semeador <cite>(cf. Mt 13)</cite>.</p>

<h4>Por que outros que são chamados vêm</h4>
<p>Outros que são chamados pelo ministério do Evangelho vêm e são convertidos. Isto não pode ser atribuído ao homem, como se ele se distinguisse por sua livre vontade de outros que receberam a mesma e suficiente graça para fé e conversão, como a heresia orgulhosa de Pelágio afirma. Mas isto deve ser atribuído a Deus: como ele os escolheu em Cristo desde a eternidade, assim ele os chamou efetivamente no tempo. Ele lhes dá fé e arrependimento; ele os livra do poder das trevas e os transfere para o reino de seu Filho.</p>
<p>Tudo isso ele faz a fim de que proclamem as grandes virtudes daquele que os chamou das trevas para a sua maravilhosa luz e se gloriem, não em si mesmos, mas no Senhor, como é o testemunho geral dos escritos apostólicos <cite>(Cl 1.13; 1Pe 2.9; 1Co 1.31)</cite>.</p>

<h4>Como ocorre a conversão</h4>
<p>Deus realiza seu bom propósito nos eleitos e opera neles a verdadeira conversão da seguinte maneira: ele faz com que ouçam o Evangelho mediante a pregação e poderosamente ilumina suas mentes pelo Espírito Santo de tal modo que possam entender corretamente e discernir as coisas do Espírito de Deus. Mas, pela operação eficaz do mesmo Espírito regenerador, Deus também pene  tra até os recantos mais íntimos do homem. Ele abre o coração fechado e enternece o que está duro, circuncida o que está incircunciso e introduz novas qualidades na vontade. Esta vontade estava morta, mas ele a faz reviver; era má, mas ele a torna boa; estava indisposta, mas ele a torna disposta; era rebelde, mas ele a faz obediente, ele move e fortalece esta vontade de tal forma que, como uma boa árvore, seja capaz de produzir frutos de boas obras <cite>(1Co 2.14)</cite>.</p>

<h4>O caráter divino do regeneração</h4>
<p>Esta conversão é aquela regeneração, renovação, nova criação, ressurreição dos mortos e vivificação, tão exaltada nas Escrituras, a qual Deus opera em nós, sem qualquer contribuição de nossa parte. Mas esta regeneração não é efetuada pela pregação apenas, nem por persuasão moral. Nem ocorre de tal maneira que, havendo Deus feito a sua parte, resta ao poder do homem ser regenerado ou não regenerado, convertido ou não convertido. Ao contrário, a regeneração é uma obra sobrenatural, poderosíssima, e ao mesmo tempo agradabilíssima, maravilhosa, misteriosa e indizível. De acordo com o testemunho da Escritura, inspirada pelo próprio autor dessa obra, regeneração não é inferior em poder à criação ou à ressurreição dos mortos. Consequentemente todos aqueles em cujos corações Deus opera desta maneira maravilhosa são, certamente, infalível e efetivamente regenerados e de fatopassam a crer. Portanto a vontade que é renovada não apenas é acionada e movida por Deus mas, sob a ação de Deus, torna se ela mesma atuante. Por isso também se diz corretamente que o homem crê e se arrepende mediante a graça que recebeu.</p>

<h4>A regeneração é incompreensível</h4>
<p>Como Deus opera, os crentes, enquanto vivos, não podem entender completamente. Entretanto, estão tranquilos, sabendo e sentindo que por esta graça de Deus eles creem com o coração e amam seu Salvador.</p>

<h4>Fé, um dom de Deus</h4>
<p>A fé nada mais é, portanto, que um dom de Deus. Isto significa que Deus a oferece à livre vontade do homem, mas que ela é, de fato, conferida ao homem e nele infundida. Não é um dom no sentido de que Deus apenas concede poder para crer e depois espera da livre vontade do homem o consentimento para crer ou o ato de crer. Ao contrário, é um dom no sentido de que Deus efetua no homem tanto a vontade de crer quanto o ato de crer. Ele opera tanto o querer como o realizar; sim, ele opera tudo em todos <cite>(Ef 2.8; Fp 2.13)</cite>.</p>

<h4>Atitude cristã com respeito à graça imerecida de Deus</h4>
<p>Esta graça Deus não deve a ninguém. Em troca de quê seria ele devedor ao homem? Quem teria primeiro dado a ele para que pudesse ser retribuído? O que poderia Deus dever a alguém que nada tem de si mesmo a não ser pecado e falsidade? Aquele, portanto, que recebe esta graça deve e rende eterna gratidão a Deus. Porém, quem não recebe esta graça nem valoriza estas coisas espirituais e tem prazer na sua própria situação ou numa falsa segurança, em vão se gaba de ter o que não tem. Além disso, quanto aos que manifestam sua fé e corrigem suas vidas, nós devemos julgar e falar da maneira mais favorável, de acordo com o exemplo dos apóstolos, pois não conhecemos o âmago dos corações. Quanto aos que ainda não foram chamados, nós devemos orar a Deus em seu favor, pois ele é que chama à existência as coisas que não existem. De maneira nenhuma, porém, podemos ter uma atitude orgulhosa para com eles, como se nós tivéssemos conquistado nossa posição distinta <cite>(Rm 11.35)</cite>.</p>

<h4>A vontade do homem não é eliminada mas vivificada</h4>
<p>O homem não deixou, apesar da queda, de ser homem dotado de intelecto e vontade; e o pecado, que tem penetrado em toda a raça humana, não privou o homem de sua natureza humana, mas trouxe sobre ele depravação e morte espiritual. Assim também a graça divina da regeneração não age sobre os homens como se fossem máquinas ou robôs, e não destrói a vontade e as suas propriedades, ou a coage violentamente. Mas a graça a faz reviver espiritualmente, traz lhe a cura, corrige a e a dobra de forma agradável e ao mesmo tempo poderosa. Como resultado, onde dominava rebelião e resistência da carne, agora, pelo Espírito, começa a prevalecer uma pronta e sincera obediência. Esta é a verdadeira renovação espiritual e liberdade da vontade. E se o admirável autor de todo bem não agisse desse modo conosco, o homem não teria esperança de levantar se da sua queda por meio de sua livre vontade, pela qual ele, quando ainda estava em pé, se lançou na perdição.</p>

<h4>O uso dos meios</h4>
<p>A todo poderosa operação de Deus pela qual ele produz e sustenta nossa vida natural não exclui mas requer o uso de meios, pelos quais ele quis exercer seu poder, de acordo com sua infinita sabedoria e bondade. Da mesma maneira a mencionada operação sobrenatural de Deus, pela qual ele nos regenera, de modo nenhum exclui ou anula o uso do Evangelho, que o mui sábio Deus ordenou para ser a semente da regeneração e o alimento da alma. Por esta razão os apóstolos e os mestres que os sucederam, piedosamente instruíram o povo acerca da graça de Deus, para sua glo.ria e para humilhação de toda soberba do homem. Ao mesmo tempo eles não descuidaram de manter o povo, pelas santas admoestações do Evangelho, sob a ministração da Palavra, dos sacramentos e da disciplina.</p>
<p>Por isso aqueles que hoje ensinam ou aprendem na igreja não devem ousar tentar a Deus, separando aquilo que ele em seu bom propósito quis preservar inteiramente unido. Pois a graça é conferida através de admoestações, e quanto mais prontamente desempenhamos nosso dever, tanto mais este beneficio de Deus, que opera em nós, se manifesta gloriosamente e sua obra prossegue da melhor maneira. A Deus somente seja dada toda glória eternamente, tanto pelos meios quanto pelo fruto e eficácia da salvação. Amém.</p>

<hr />
<div class="erros">
    <h3>Rejeição de erros</h3>
    <p>Tendo explicado a doutrina ortodoxo, o Sínodo rejeita os seguintes erros:</p>
    <p>É impróprio dizer que o pecado original em si é suficiente para condenar toda a raça humana ou merecer castigo temporal e eterno.</p>
    <p>Isto contradiz o apóstolo que declara: <q>Assim como por um só homem entrou o pecado no mundo, e pelo pecado, a morte, assim também a morte passou a todos os homens porque todos pecaram</q> <cite>(Rm 5.12)</cite>. E o versículo 16 diz: <q>O julgamento derivou de uma só ofensa, para a condenação</q>. E em Romanos 6.23: <q>O salário do pecado é a morte</q>.</p>
    <p>Os dons espirituais ou as boas qualidades e virtudes, tal como a bondade, a santidade e a justiça, não podiam estar na vontade do homem quando no princípio foi criado. Por isso também não podiam ter sido separados da sua própria vontade quando caiu.</p>
    <p>Este erro é contrário à descrição da imagem de Deus que o apóstolo dá em Efésios 4.24, dizendo que ela consiste em justiça e santidade, que sem dúvida estão na vontade.</p>
    <p>Na morte espiritual os dons espirituais não são separados da vontade do homem. Porque a vontade como tal nunca tem sido corrompida, mas apenas atrapalhada pelo obscurecimento do entendimento e pela desordem das afeições. Se estes obstáculos forem removidos, a vontade pode exercer seu livre poder inato. A vontade é por si mesma capaz de desejar e escolher ou não toda espécie de bem que lhe for apresentada.</p>
    <p>Esta é uma novidade e um engano, e tende a exaltar os poderes da livre vontade, contrário ao que o profeta Jeremias declara em 17.9: <q>Enganoso é o coração, mais do que todas as coisas, e desesperadamente corrupto</q> E o apóstolo Paulo escreve: <q>Entre os quais (os filhos da desobediência) também todos nós andamos outrora, segundo as inclinações da nossa carne, fazendo a vontade da carne e dos pensamentos</q> <cite>(Ef 2.3)</cite>.</p>
    <p>O homem não regenerado não é realmente ou totalmente morto em pecados, ou privado de toda capacidade para fazer o bem. Ele ainda pode ter fome e sede de justiça e vida, e pode oferecer sacrifício de espírito contrito e quebrantado que agrada a Deus.</p>
    <p>Estas afirmações são contrárias ao testemunho claro da Escritura: <q>Ele vos deu vida, estando vós mortos nos vossos delitos e pecados</q> <cite>(Ef 2.1; cf. v.5).</cite> <q>E, era continuamente mau todo o desígnio do seu coração</q> <cite>(Gn 6.5; cf. 8.21)</cite>. Além do mais, somente os regenerados e os bem aventurados têm fome e sede da libertação da miséria e da vida, e oferecem a Deus um sacrifício de espírito quebrantado <cite>(Sl 51.19 e Mt 5.6)</cite>.</p>
    <p>O homem degenerado e carnal bem pode usar a graça comum (na que se constitui a luz natural), ou os dons que ainda lhe foram deixados após a queda. Assim ele, sozinho, pode gradualmente alcançar uma graça maior, isto é, a graça evangélica ou salvadora, e a própria salvação. Dessa forma Deus, por seu lado, mostra se pronto a revelar Cristo a todo homem, porque a todos ele administra suficiente e efetivamente os meios necessários para conhecer Cristo, para crer e se arrepender.</p>
    <p>Tanto a experiência de todas as épocas como a Escritura testificam que isto não é verdade. <q>Mostra a sua palavra a Jacó, as suas leis e os seus preceitos a Israel. Não fez assim a nenhuma outra nação; todos ignoram os seus preceitos.</q> <cite>(Sl 147.19–20)</cite>. E ainda: <q>O qual nos gerações passadas permitiu que todos os povos andassem nos seus próprios caminhos</q> <cite>(At 14.16)</cite>. E Paulo e seus companheiros foram <q>impedidos pelo Espírito Santo de pregar a Palavra no Ásia, defrontando Mísia, tentavam ir para Bitínia, mas o Espírito de Jesus não o permitiu</q> <cite>(At 16.6–7)</cite>.</p>
    <p>Na verdadeira conversão do homem, Deus não pode infundir novas qualidades, novos poderes ou dons na vontade humana. Portanto a fé, que é o começo da conversão, e que nos dá o nome de crentes, não é uma qualidade ou um dom outorgado por Deus mas apenas um ato do homem. Somente com respeito ao poder para alcançar a fé, pode se dizer que é um dom.</p>
    <p>Este ensinamento contradiz a Sagrada Escritura quando declara que Deus infunde em nossos corações novas qualidades de fé, obediência e experiência de seu amor: <q>Na mente lhes imprimirei as minhas leis, também nos corações lhas inscreverei</q> <cite>(Jr 31.33)</cite>. E: <q>Derramarei água sobre o sedento, e torrentes sobre a terra seca</q> <cite>(Is 44.3)</cite>. E ainda: <q>O amor de Deus é derramado em nossos corações pelo Espírito Santo que nos foi outorgado</q> <cite>(Rm 5.5)</cite>. O ensinamento arminiano também contraria a prática constante da Igreja, que ora com o profeta: <q>Converte-me, e serei convertido</q> <cite>(Jr 31.18)</cite>.</p>
    <p>Esta graça pela qual somos convertidos a Deus é apenas um apelo gentil. Ou (como alguns explicam) esta maneira de agir, que consiste em aconselhar, é a mais nobre maneira de converter o homem e está mais em harmonia com a natureza deste. Não há razão por que tal graça persuasiva não seja suficiente para tomar espiritual o homem natural. Em verdade, Deus não produz o consentimento da vontade a não ser através deste tipo de apelo moral. O poder da operação divina supera a ação de Satapás, Deus prometendo bens eternos e Satanás apenas bens temporais.</p>
    <p>Isto é Pelagianismo por completo, e contrário a toda Escritura que conhece, além desse apelo moral, outra operação, muito mais poderosa e divina: a ação do Espírito Santo na conversão do homem: <q>Dar vos ei coração novo, e porei dentro em vós espírito novo; tirarei de vós o coração de pedra e vos darei coração de carne</q> <cite>(Ez 36.26)</cite>.</p>
    <p>Na regeneração do homem Deus não usa os poderes de sua onipotência de maneira a dobrar a vontade do homem, à força e infalivelmente, para a fé e a conversão. Mesmo sendo realizadas todas as operações da graça que Deus possa usar para converter o homem e mesmo que Deus tenha a intenção e a vontade de regenerá lo, o homem ainda pode resistir a Deus e ao Santo Espírito. De fato frequentemente resiste, chegando a impedir totalmente sua regeneração. Portanto ser ou não ser regenerado permanece no arbítrio do homem.</p>
    <p>Isto é nada mais nada menos que anular todo o poder da graça de Deus em nossa conversão e sujeitar a operação do Deus Todo Poderoso à vontade do homem. É contrário ao que os apóstolos ensinam: cremos <q>&hellip; segundo a eficácia do força do seu poder</q> <cite>(Ef 1. 19)</cite>, e <q>para que nosso Deus cumpra&hellip; com poder todo propósito de bondade e obra de fé</q> <cite>(2Ts 1.11)</cite>, e também <q>pelo seu divino poder nos têm sido doadas todas as coisas que conduzem à vida e à piedade</q> <cite>(2Pe 1.3)</cite>.</p>
    <p>Graça e livre vontade são as causas parciais que operam juntas no início da conversão. Pela ordem destas causas a graça não precede à operação da vontade do homem. Deus não ajuda efetivamente a vontade do homem para sua conversão, enquanto a própria vontade do homem não se move e decide se converter.</p>
    <p>A Igreja Antiga há muito tempo já condenou esta doutrina dos Pelagianos, de acordo com a palavra do apóstolo: <q>Assim, pois, não depende de quem quer, ou de quem corre, mas de usar Deus a sua misericórdia</q> <cite>(Rm 9.16)</cite>. Também: <q>Quem é que te faz sobressair? E que tens tu que não tenhas recebido?</q> <cite>(1Co 4.7)</cite>. E ainda: <q>Deus é quem efetua em vós tanto o querer como o realizar, segundo a sua boa vontade</q> <cite>(Fp 2.13)</cite>.</p>
</div>
<hr />
<h2>5º Capítulo da Doutrina</h2>
<h3>A Perseverança dos Santos</h3>

<h4>O regenerado não está livre do seu pecado</h4>
<p>Aqueles que, de acordo com o seu propósito, Deus chama à comunhão do seu Filho, nosso Senhor Jesus Cristo, e regenera pelo seu Santo Espírito, ele certamente os livra do domínio e da escravidão do pecado. Mas nesta vida, ele não os livra totalmente da carne e do corpo de pecado <cite>(Rm 7.24)</cite>.</p>

<h4>Pecados diários de fraqueza</h4>
<p>Portanto, pecados diários de fraqueza surgem e até as melhores obras dos santos são imperfeitas.</p>
<p>Estes são para eles constante motivo para humilhar se perante Deus e refugiar se no Cristo crucificado. Também são motivo para mais e mais mortificar a carne através do espírito de oração e através dos Santos exercícios de piedade, e ansiar pela meta da perfeição. Eles fazem isto até que possam reinar com o Cordeiro de Deus nos céus, finalmente livres deste corpo de morte.</p>

<h4>Deus preserva os seus</h4>
<p>Por causa dos seus pecados remanescentes e também por causa das tentações do mundo e de Satanás, aqueles que têm sido convertidos não poderiam perseverar nesta graça se deixados ao cuidado de suas próprias forças. Mas Deus é fiel: misericordiosamente os confirma na graça, uma vez conferida a eles, e poderosamente os preserva [na sua graça] até o fim.</p>

<h4>Os santos podem cair em pecados sérios</h4>
<p>O poder de Deus, pelo qual ele confirma e preserva os verdadeiros crentes na graça, é tão grande que isto não pode ser vencido pela carne. Mas os convertidos nem sempre são guiados e movidos por Deus, e assim eles poderiam, em certos casos, por sua própria culpa, desviar se da direção da graça e ser seduzidos pelos desejos da carne e segui los. Devem, portanto, vigiar constantemente e orar para que não caiam em tentação. Quando não vigiarem e orarem, eles podem ser levados pela carne, pelo mundo e por Satanás para sérios e horríveis pecados. Isto ocorre também muitas vezes pela justa permissão de Deus. A lamentável queda de Davi, Pedro e outros santos, descrita na Sagrada Escritura, demonstra isso.</p>

<h4>Os efeitos de tais pecados sérios</h4>
<p>Por tais pecados grosseiros, entretanto, eles causam a ira de Deus, se tornam culpados de morte, entristecem o Espírito Santo, suspendem o exercício da fé, ferem profundamente suas consciências e algumas vezes perdem temporariamente a sensação da graça. Mas quando retornam ao reto caminho por meio de arrependimento sincero, logo a face paternal de Deus brilha novamente sobre eles.</p>

<h4>Deus não permite que seus eleitos se percam</h4>
<p>Pois Deus, que é rico em misericórdia, de acordo com o imutável propósito da eleição, não retira completamente o seu Espírito dos seus, mesmo em sua deplorável queda. Nem tampouco permite que venham a cair tanto que recaiam da graça da adoção e do estado de justificados. Nem permite que cometam o pecado que leva à morte, isto é, o pecado contra o Espírito Santo e assim sejam totalmente abandonados por ele, lançando se na perdição eterna.</p>

<h4>Deus quer renovar os eleitos para arrependimento</h4>
<p>Pois, em primeiro lugar, em tal queda, Deus preserva neles sua Imperecível semente da regeneração, a fim de que esta não pereça nem seja lançada fora. Além disso, através da sua Palavra e de seu Espírito, ele certamente os renova efetivamente para arrependimento. Como resultado eles se afligem de coração, entristecendo se com Deus pelos pecados que têm cometido; procuram e obtêm pela fé, com coração contríto, o perdão pelo sangue do Mediador; e experimentam novamente a graça de Deus, que se reconcilia com eles que, através da fé adoram sua misericórdia. E aí em diante eles se empenham mais diligentemente pela sua salvação com temor e tremor.</p>

<h4>A graça do trino Deus preserva</h4>
<p>Assim, não é por seus próprios méritos ou força, mas pela imerecida misericórdia de Deus que eles não caem totalmente da fé e da graça e nem permanecem caldos ou se perdem definitivamente. Quanto a eles, isto facilmente poderia acontecer e aconteceria sem dúvida. Quanto a Deus, porém, isto não pode acontecer de modo nenhum. Pois seu decreto não pode ser mudado, sua promessa não pode ser quebrada, seu chamado em acordo com seu propósito não pode ser revogado. Nem o mérito, a intercessão ou a preservação de Cristo podem ser invalidados, e a selagem do Espírito tampouco pode ser frustada ou destrulda.</p>

<h4>A certeza desta preservação</h4>
<p>Os crentes podem estar certos e estão certos dessa preservação dos eleitos para a salvação e da perseverança dos verdadeiros crentes na fé. Esta certeza ocorre de acordo com a medida de sua fé, pela qual eles creem que são e permanecerão verdadeiros e vivos membros da Igreja, e que têm o perdão dos pecados e a vida eterna.</p>

<h4>O fundamento desta certeza</h4>
<p>Esta certeza não vem de uma revelação especial, sem a Palavra ou fora dela, mas vem da fé nas promessas de Deus, que ele revelou abundantemente em sua Palavra para nossa consolação; vem também do testemunho do Espírito Santo, testificando com o nosso espírito que somos filhos e herdeiros de Deus; e, finalmente, vem do zelo sério e santo por uma boa consciência e por boas obras. E se os eleitos não tivessem neste mundo a sólida consolação de obter a vitória e esta garantia infalível da glória eterna, seriam os mais miseráveis de todos os homens <cite>(Rm 8.16-17)</cite>.</p>

<h4>Esta certeza nem sempre é sentida</h4>
<p>No entanto, a Escritura testifica que os crentes nesta vida têm de lutar contra várias dúvidas da carne e, sujeitos a graves tentações, nem sempre sentem plenamente esta confiança da fé e certeza da perseverança. Mas Deus, que é Pai de toda a consolação, não os deixa ser tentados além de suas forças, mas com a tentação proverá também o livramento e pelo Espírito Santo novamente revive neles a certeza da perseverança <cite>(1Co 10.13)</cite>.</p>

<h4>Esta certeza não leva à acomodação</h4>
<p>Entretanto, esta certeza de perseverança não faz de maneira nenhuma com que os verdadeiros crentes se orgulhem e se acomodem. Ao contrário, ela é a verdadeira raiz da humildade, reverência filial, verdadeira piedade, paciência em toda luta, orações fervorosas, firmeza em carregar a cruz e confessar a verdade e alegria sólida em Deus. Além do mais, a reflexão deste benefício é para eles um estímulo para praticar séria e constantemente a gratidão e as boas obras, como é evidente nos testemunhos da Escritura e nos exemplos dos santos.</p>

<h4>Esta certeza produz diligência</h4>
<p>Quando pessoas são levantadas de uma queda (no pecado) começam a reviver a confiança na perseverança. Isto não produz descuido ou negligência na piedade delas. Em vez disto produz maior cuidado e diligência para guardar os caminhos do Senhor, já preparados, para que, andando neles, possam preservar a certeza da perseverança. Quando fazem isto, o Deus reconciliado não retira de novo sua face delas por causa do abuso da sua bondade paternal (a contemplação dela é para os piedosos mais doce que a vida e sua retirada mais amarga que a morte), e elas não cairão em tormentos mais graves da alma <cite>(Ef 2.10)</cite>.</p>

<h4>Incluído o uso de meios</h4>
<p>Tal como agradou a Deus iniciar sua obra da graça em nós pela pregação do evangelho, assim ele a mantém, continua e aperfeiçoa pelo ouvir e ler do Evangelho, pelo meditar nele, pelas suas exortações, ameaças e promessas, e pelo uso dos sacramentos.</p>

<h4>Este doutrina é odiado por Satanás mos amado pela Igreja</h4>
<p>Deus revelou abundantemente em sua Palavra esta doutrina da perseverança dos verdadeiros crentes e santos e da certeza dela para a glória do seu Nome e para a consolação dos piedosos. Ele a imprime nos corações dos crentes, mas a carne não pode entendêIa. Satanás a odeia, o mundo zomba dela, os ignorantes e hipócritas dela abusam, e os heréticos a ela se opõem. A Noiva de Cristo, entretanto, sempre tem na amado ternamente e defendido constantemente como um tesouro de inestimável valor. Deus, contra quem nenhum plano pode se valer e nenhuma força pode prevalecer, cuidará para que a Igreja possa continuar fazendo isso. Ao único Deus, Pai, Filho e Espírito Santo, sejam a honra e a glória para sempre. Amém!</p>

<hr />
<div class="erros">
    <h3>Rejeição de erros</h3>
    <p>Tendo explicado a doutrina ortodoxa, o Sínodo rejeita os seguintes erros:</p>
    <p>A perseverança dos verdadeiros crentes não é resultado da eleição ou um dom de Deus obtido pela morte de Cristo. É uma condição da nova aliança, que o homem deve cumprir pela sua livre vontade antes da assim chamada eleição decisiva e justifícação.</p>
    <p>A Escritura Sagrada testifica que a perseverança provém da eleição e é dada aos eleitos pelo poder da morte, ressurreição e intercessão de Cristo: <q>A eleição o alcançou; e os mais foram endurecidos</q> <cite>(Rm 11.7)</cite>. Também: <q>Aquele que não poupou o seu próprio Filho, antes, por todos nós o entregou, porventura não nos dará graciosamente com ele todas as coisas? Quem intentará acusação contra os eleitos de Deus? É Deus quem os justifica. Quem os condenará? É Cristo Jesus quem morreu, ou antes, quem ressuscitou, o qual está à direita de Deus, e também intercede por nós. Quem nos separará do amor de Cristo?</q> <cite>(Rm 8.32–35)</cite>.</p>
    <p>Deus de fato provê os crentes de suficientes forças para perseverar e está pronto para preservar tais forças neles, se estes cumprirem seu dever; mas ainda que todas estas coisas tenham sido estabelecidas como necessárias para perseverar na fé e que Deus as use para preservar a fé, ainda assim dependerá da vontade humana perseverar ou não.</p>
    <p>Esta idéia é abertamente pelagiana. Enquanto deseja libertar o homem, o faz usurpador da honra de Deus. Combate o consenso geral da doutrina evangélica que retira do homem todo motivo de orgulho e atribui todo louvor por este benefício somente à graça de Deus.
    <br />É também contrário ao apóstolo que declara: <q>O qual também vos confirmará até.ao fim, para serdes irrepreensíveis no dia de nosso Senhor Jesus Cristo</q> <cite>(1Co 1.8)</cite>.</p>
    <p>Crentes verdadeiramente regenerados não só podem perder completa e definitivamente a fé justificadora, a graça e a salvação, mas de fato as perdem frequentemente e assim se perdem eternamente.</p>
    <p>Esta opinião invalida a graça, a justificação, a regeneração e contínua preservação por Cristo. Ela écontrária às palavras expressas do apóstolo Paulo: <q>Deus prova o seu próprio amor para conosco, pelo fato de ter Cristo morrido por nós, sendo nós ainda pecadores. Logo, muito mais agora, sendo justificados pelo seu sangue, seremos por ele salvos da ira</q> <cite>(Rm 5.8–9)</cite>.
    <br />
    É contrária ao apóstolo João: <q>Todo aquele que é nascido de Deus não vive na prático do pecado; pois o que permanece nele é a divina semente; ora, esse não pode viver pecando porque é nascido de Deus</q> <cite>(1Jo 3.9)</cite>. Também é contrária às palavras de Jesus Cristo: <q>Eu lhes dou a vida eterna; jamais perecerão, eternamente, e ninguém as arrebatará da minha mão. Aquilo que meu Pai me deu é maior do que tudo; e da mão do Pai ninguém pode arrebatar</q> <cite>(Jo 10.28–29)</cite>.</p>
    <p>Verdadeiros crentes regenerados podem cometer o pecado que leva à morte, ou o pecado contra o Espírito Santo.</p>
    <p>Após o apóstolo João ter falado no 59 capítulo de sua primeira carta, versos 16 e 17, sobre aqueles que pecam para a morte e de ter proibido de orar por eles, logo acrescenta no verso 18: <q>Sabemos que todo aquele que é nascido de Deus não vive em pecado, antes, aquele que nasceu de Deus o guarda, e o maligno não o toca.</q></p>
    <p>Sem uma revelação especial não podemos ter nesta vida nenhuma certeza da perseverança futura.</p>
    <p>Por tal doutrina o seguro consolo dos crentes verdadeiros nesta vida é tirado, e as dúvidas dos seguidores do papa são novamente introduzidas na igreja. As Escrituras Sagradas, entretanto, sempre deduzem esta segurança, não a partir de uma revelação especial e extraordinária, mas a partir das marcas dos Mos de Deus e das promessas mui firmes dele. Especialmente o apóstolo Paulo ensina isto: <q>Nem qualquer outra criatura poderá separar nos do amor de Deus que há em Cristo Jesus, nosso Senhor</q> <cite>(Rm 8:39)</cite>. E João escreve: <q>Aquele que guarda os seus mandamentos permanece em Deus, e Deus nele. E nisto conhecemos que ele permanece em nós, pelo Espírito que nos deu</q> <cite>(1Jo 3.24)</cite>.</p>
    <p>Por sua própria natureza a doutrina da certeza da perseverança e da salvação causa falsa segurança e prejudica a piedade, os bons costumes, orações e outros santos exercícios. Ao contrário, é louvável duvidar desta certeza.</p>
    <p>Esta falsa doutrina ignora o efetivo poder da graça de Deus e a atuação do Santo Espírito, que habita em nós. Contradiz o apóstolo João que, em palavras explícitas, ensina o contrário: <q>Amados, agora somos filhos de Deus, e ainda não se manifestou o que havemos de ser Sabemos que, quando ele se manifestar, seremos semelhantes a ele, porque havemos de vê lo como ele é. E a si mesmo se purifica todo o que nele tem esta esperança, assim como ele é puro</q> <cite>(1Jo 3.2–3)</cite>. Ainda mais, ela é refutada pelos exemplos dos santos tanto no Antigo como no Novo Testamento que, não obstante estarem certos de sua perseverança e salvação, continuaram em oração e outros exercícios de piedade.</p>
    <p>A fé daqueles que creem apenas por um tempo não é diferente da fé justificadora e salvadora, a não ser com respeito à sua duração.</p>
    <p>Em Mateus 13.20–23 e Lucas 8.13,15 Cristo mesmo indica claramente, além da duração, uma tríplice diferença entre os que creem só por um tempo e os verdadeiros crentes. Ele declara que o primeiro recebe a semente em terra rochosa, mas o último em bom solo, ou seja, em bom coração; que o primeiro é sem raiz, mas o último tem firme raiz; que o primeiro não tem fruto, mas o último produz fruto em várias medidas, constante e perseverantemente.</p>
    <p>Não é absurdo o fato de alguém, tendo perdido sua primeira regeneração, nascer de novo e mesmo frequentemente nascer de novo.</p>
    <p>Esta doutrina nega que a semente de Deus, pela qual somos nascidos de novo, seja incorruptível. Isto écontrário ao testemunho do apóstolo Pedro: <q>Fostes regenerados, não de semente corruptível, mas de incorruptível</q> <cite>(1Pe 1.23)</cite>.</p>
    <p>Cristo em lugar algum orou para que os crentes perseverassem infalivelmente na fé.</p>
    <p>Isto contradiz ao próprio Cristo, que disse: <q>Eu, porém, roguei por ti <span>[Pedro]</span> para que a tua fé não desfaleça</q> <cite>(Lc 22.32)</cite>. Também contradiz o apóstolo João que declara que Cristo não orava somente pelos apóstolos, mas também por todos aqueles que viessem a crer por meio da palavra deles: <q>Pai Santo, guarda os em teu nome, que me deste&hellip; Não peço que os tires do mundo; e sim, que os guardes do mal</q> <cite>(Jo 17.11,15)</cite>.</p>
</div>
            </Col>
        </Row>
      {/* Rodapé */}
      <Footer />
    </Container>
  </div>
)
